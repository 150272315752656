<template>
  <div>
    <v-card flat outline class="accent mb-3 pa-4">
      <h2 v-if="!isCheckout" class="mb-3">
        {{ $t("checkout.summary.cart") }}
      </h2>

      <v-row
        no-gutters
        class="d-flex justify-space-between align-center text-body-1 mb-2"
      >
        <strong>{{
          isCheckout
            ? $t("order.summary.itemsTotal")
            : $t("checkout.summary.itemsTotal")
        }}</strong>
        <strong>
          {{ $n(netTotal, "currency") }}
        </strong>
      </v-row>
      <!-- totalPriceVariable -->
      <v-row
        v-if="orderCart.totalPriceVariable > 0"
        no-gutters
        class="d-flex justify-space-between align-center text-body-2"
      >
        <strong>{{ $t("checkout.summary.itemsTotalByWeight") }}</strong>
        <strong>{{ $n(orderCart.totalPriceVariable, "currency") }}</strong>
      </v-row>
    </v-card>
    <v-card v-if="!isCheckout" flat outline class="accent  mb-3 pa-4">
      <div class="gift-code-input d-flex align-center">
        <label><strong>Codice sconto </strong></label>
        <v-text-field
          class="mx-2"
          label="Inserisci codice"
          hide-details="auto"
          v-model="giftCode"
          outlined
          dense
          filled
          max-width="200px"
        ></v-text-field>
        <v-spacer />
        <v-btn medium v-on:click="sendGiftCode(giftCode)" color="primary">
          AGGIUNGI
        </v-btn>
      </div>
      <v-row no-gutters class="flex-column">
        <div
          v-for="gift in orderCart.giftCertificates"
          :key="gift.giftCertificateId"
          class="d-flex align-center mb-3 justify-space-between"
        >
          <div class="d-flex justify-space-between">
            <span class="summary-text">{{ gift.description }}</span>
          </div>
          <v-btn icon @click="removeGiftCode(gift.userGiftCertificateId)" right>
            <v-icon color="primary">$delete</v-icon>
          </v-btn>
        </div>
        <div
          class="d-flex justify-space-between align-top mt-3 text-caption font-weight-bold"
          v-for="gift in orderCart.giftCertificatesToGenerate"
          :key="gift.giftCertificateId"
        >
          <span
            class="summary-text px-2 white--text text-uppercase secondary"
            >{{ gift.description }}</span
          >
          <span class="summary-value" v-if="gift.giftTotal">
            {{ $n(gift.giftTotal, "currency") }}</span
          >
        </div>
      </v-row>
      <!-- saved -->
      <v-row
        v-if="orderCart?.giftTotal > 0"
        no-gutters
        justify="space-between"
        class="mt-4 font-italic"
      >
        <strong>Totale buoni sconto applicati</strong>
        <span>{{ $n(orderCart.giftTotal, "currency") }}</span>
      </v-row>
    </v-card>
    <v-card flat outline class="accent mb-3 pa-4">
      <v-row
        v-if="orderCart?.deliveryFee > 0"
        no-gutters
        justify="space-between"
        align="center"
        class="mt-4"
      >
        <span>{{ $t("cartSummary.deliveryFee") }}</span>
        <span>{{ $n(orderCart.deliveryFee, "currency") }}</span>
      </v-row>
      <v-row
        no-gutters
        class="d-flex justify-space-between align-center text-uppercase"
      >
        <h3>
          {{ isCheckout ? "Totale:" : $t("cartSummary.orderGrossTotal") }}
        </h3>
        <h3>
          <OrderGrossTotal
            :item="orderCart"
            :delivered="delivered"
          ></OrderGrossTotal>
        </h3>
      </v-row>
    </v-card>
  </div>
</template>
<style lang="scss">
.gift-code-input {
  .v-input {
    max-width: fit-content;
  }
}
</style>
<script>
import OrderGrossTotal from "@/components/orders/OrderGrossTotal";
import { mapActions } from "vuex";

export default {
  name: "CheckoutSummary",
  components: { OrderGrossTotal },
  data() {
    return {
      giftCode: ""
    };
  },
  props: {
    orderCart: { type: Object, required: true },
    isCheckout: { type: Boolean, default: true },
    delivered: { type: Boolean, default: false }
  },
  computed: {
    netTotal() {
      return this.orderCart.orderId
        ? this.orderCart.netTotal
        : this.orderCart.totalPrice;
    }
  },
  methods: {
    ...mapActions({
      cartAddGiftCode: "cart/addGiftCode",
      cartSendGiftCode: "cart/sendGiftCode",
      cartRemoveGiftCode: "cart/removeGiftCode"
    }),
    addGiftCode(giftCode) {
      this.cartAddGiftCode(giftCode);
      this.$emit("giftChanged");
    },
    sendGiftCode(giftCode) {
      this.cartSendGiftCode(giftCode);
      this.$emit("giftChanged");
    },
    removeGiftCode(giftCode) {
      this.cartRemoveGiftCode(giftCode);
      this.$emit("giftChanged");
    }
  }
};
</script>
